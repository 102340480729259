import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
  OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  AppInputDirective,
  ButtonComponent,
  DropdownSelectComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  ModalComponent,
  ModalContentComponent,
  ModalFooterComponent
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import {
  MailConfigCustomer,
  MailServiceType,
  NameValue
} from '@ui/shared/models';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs';
import {
  ChangeMailConfigCustomer,
  getMailConfigCustomer
} from '../../../../../../+state';

@UntilDestroy()
@Component({
  selector: 'app-mail-config-customer-modal',
  templateUrl: './mail-config-customer-modal.component.html',
  styleUrls: ['./mail-config-customer-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ModalComponent,
    ModalContentComponent,
    TranslateModule,
    FormFieldComponent,
    FormsModule,
    ModalFooterComponent,
    ButtonComponent,
    ReactiveFormsModule,
    AppInputDirective,
    DropdownSelectComponent,
    FormFieldLabelComponent
  ]
})
export class MailConfigurationModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);
  private fb = inject(FormBuilder);
  private store = inject(Store);
  public mailServiceTypes: NameValue[] = [
    {
      name: 'FlowMailer',
      value: MailServiceType.FLOWMAILER
    },
    {
      name: 'SendGrid',
      value: MailServiceType.SENDGRID
    }
  ];
  public form = this.fb.group({
    mailService: [MailServiceType.FLOWMAILER, Validators.required],
    senderAddressPrefix: '',
    senderNamePrefix: '',
    senderDomain: ['', Validators.required]
  });
  customerId = input(null);

  okAction() {
    this.store.dispatch(
      new ChangeMailConfigCustomer(
        this.customerId(),
        this.form.value as MailConfigCustomer
      )
    );
    this.ngbActiveModal.close();
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }

  ngOnInit(): void {
    this.store
      .select(getMailConfigCustomer)
      .pipe(
        filter(mailConfig => !!mailConfig),
        untilDestroyed(this)
      )
      .subscribe((mailConfig: MailConfigCustomer) => {
        this.form.patchValue(mailConfig);
      });
    this.form.controls.mailService.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(mailService => {
        const senderDomainControl = this.form.controls.senderDomain;

        if (mailService === MailServiceType.FLOWMAILER) {
          senderDomainControl.addValidators(Validators.required);
        } else {
          senderDomainControl.removeValidators(Validators.required);
        }
        senderDomainControl.updateValueAndValidity();
      });
  }
}
