<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body deactivate-product-renew-modal__body mb20">
        <p class="title-m deactivate-product-renew-modal__title">
          {{ 'customer.change_mail_config' | translate }}
        </p>
        <form [formGroup]="form">
          <app-form-field class="col-6 mb20">
            <h4 class="title-s mb10">
              {{ 'customer.change_mail_config_mailService_header' | translate }}
            </h4>
            <app-dropdown-select
              [items]="mailServiceTypes"
              [placeholder]="'select.type_l' | translate"
              [formControl]="form.controls.mailService"
            >
              <div dropdown-button>
                {{ 'select.type_l' | translate }}
              </div>
              <ng-template let-item>{{ item.name }}</ng-template>
            </app-dropdown-select>
          </app-form-field>
          <app-form-field class="col-6 mb20">
            <h4 class="title-s mb10">
              {{
                'customer.change_mail_config_sendAddressPrefix_header'
                  | translate
              }}
            </h4>
            <div class="d-flex">
              <input
                appInput
                [placeholder]="
                  'customer.change_mail_config_sendAddressPrefix_placeholder_l'
                    | translate
                "
                [formControl]="form.controls.senderAddressPrefix"
                class="form-control"
              />
            </div>
          </app-form-field>
          <app-form-field class="col-6">
            <h4 class="title-s mb10">
              {{
                'customer.change_mail_config_senderNamePrefix_header'
                  | translate
              }}
            </h4>
            <div class="d-flex">
              <input
                appInput
                [placeholder]="
                  'customer.change_mail_config_senderNamePrefix_placeholder_l'
                    | translate
                "
                [formControl]="form.controls.senderNamePrefix"
                class="form-control"
              />
            </div>
          </app-form-field>
          <app-form-field class="col-6">
            <app-form-field-label
              class="title-s mb10"
              [showRequiredMarker]="
                form.controls.mailService.value === 'FLOWMAILER'
              "
            >
              {{
                'customer.change_mail_config_senderDomain_header' | translate
              }}
            </app-form-field-label>

            <div class="d-flex">
              <input
                appInput
                [placeholder]="
                  'customer.change_mail_config_senderDomain_placeholder_l'
                    | translate
                "
                [formControl]="form.controls.senderDomain"
                class="form-control"
              />
            </div>
          </app-form-field>
        </form>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer deactivate-product-renew-modal__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="cancelAction()"
          >{{ 'general.cancel_a' | translate }}
        </app-button>
        <app-button (clickEvent)="okAction()" [disabled]="form.invalid"
          >{{ 'general.ok' | translate }}
        </app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
